export default function WhatsAppButton() {
    const phoneNumber = "+40770187601";
    const message = "Hello Arnold, I’d like to discuss a project!";
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  
    return (
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-24 right-8 z-[999] bg-gradient-to-r from-green-500 to-teal-500 p-4 rounded-full shadow-lg hover:from-green-600 hover:to-teal-600 transition-all duration-300 flex items-center justify-center"
        aria-label="Contact me on WhatsApp"
      >
        {/* WhatsApp Icon (SVG) */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="text-white"
        >
          <path d="M12 0C5.373 0 0 5.373 0 12c0 2.134.558 4.146 1.53 5.91L0 24l6.252-1.644A11.942 11.942 0 0012 24c6.627 0 12-5.373 12-12S18.627 0 12 0zm0 22.061c-1.858 0-3.584-.48-5.068-1.293l-.354-.21-3.706.975.996-3.632-.234-.375A9.956 9.956 0 012 12c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10zm5.98-7.12c-.324-.162-1.92-.948-2.22-1.056-.3-.108-.518-.162-.737.162-.218.324-.852 1.056-1.044 1.272-.192.216-.384.243-.708.081-.324-.162-1.368-.504-2.604-1.608-.96-.858-1.608-1.92-1.8-2.244-.192-.324-.02-.498.142-.66.146-.146.324-.324.486-.486.162-.162.216-.324.324-.54.108-.216.054-.405-.027-.567-.081-.162-.737-1.77-1.014-2.43-.27-.648-.54-.558-.737-.558-.192 0-.405 0-.618 0s-.567.081-.864.405c-.297.324-1.134 1.134-1.134 2.754s1.161 3.186 1.323 3.402c.162.216 2.268 3.456 5.49 4.842 1.998 1.026 1.998.684 2.376.648.378-.036 1.92-.78 2.196-1.53.276-.75.276-1.404.192-1.53-.084-.126-.3-.198-.624-.36z"/>
        </svg>
      </a>
    );
  }