import { useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Globe from '../components/Globe';
import gsap from 'gsap';

export default function Contact({ theme }) {
  useEffect(() => {
    gsap.fromTo(
      ".contact-title",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, delay: 0.5 }
    );
    gsap.fromTo(
      ".contact-form input, .contact-form textarea, .contact-form button",
      { opacity: 0, x: -20 },
      { opacity: 1, x: 0, stagger: 0.2, duration: 0.8, delay: 1 }
    );
  }, []);

  return (
    <div className="relative min-h-screen">
      {/* Globe Background */}
      <div className="absolute inset-0 z-0">
        <Canvas>
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} />
          <Globe texture="/earth-texture.jpg" />
        </Canvas>
      </div>
       {/* Shooting Stars (z-4) */}
       <div className="absolute inset-0 z-4" style={{ zIndex: 4 }}>
          <div className="shooting-star star-1"></div>
          <div className="shooting-star star-2"></div>
          <div className="shooting-star star-3"></div>
          <div className="shooting-star star-4"></div>
          <div className="shooting-star star-5"></div>
          <div className="shooting-star star-6"></div>
          <div className="shooting-star star-7"></div>
          <div className="shooting-star star-8"></div>
        </div>
      {/* Content */}
      <div className="relative z-10 py-16 max-w-md mx-auto">
        <h2 className="contact-title text-4xl font-bold text-center mb-12">Contact Me</h2>
        <form className="contact-form space-y-4">
          <input
            type="text"
            placeholder="Name"
            className={`w-full p-3 rounded ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-900'}`}
          />
          <input
            type="email"
            placeholder="Email"
            className={`w-full p-3 rounded ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-900'}`}
          />
          <textarea
            placeholder="Message"
            className={`w-full p-3 rounded ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-900'} h-32`}
          ></textarea>
          <button type="submit" className="w-full bg-gradient-to-r from-green-500 to-teal-500 p-3 rounded hover:from-green-600 hover:to-teal-600">Send</button>
        </form>
      </div>
    </div>
  );
}