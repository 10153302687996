import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function ProjectModal({ project, isOpen, onClose }) {
  const modalRef = useRef();

  useEffect(() => {
    if (isOpen) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, scale: 0.95 },
        { opacity: 1, scale: 1, duration: 0.5, ease: 'power2.out' }
      );
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className={`relative w-11/12 max-w-lg p-6 rounded-lg ${project.theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900'}`}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-2xl text-gray-500 hover:text-gray-700"
        >
          ×
        </button>
        {/* Modal Content */}
        <h3 className="text-2xl font-bold mb-4">{project.title}</h3>
        <img src={project.image} alt={project.title} className="w-full h-48 object-cover rounded mb-4" />
        <p className="mb-2"><strong>Technologies:</strong> {project.tech}</p>
        <p className="mb-4">{project.description}</p>
        {/* Additional Details (Example) */}
        <p className="mb-4"><strong>More Info:</strong> This project was developed over 3 months with a focus on performance and user experience.</p>
        {/* Live Demo Link */}
        <a
          href={project.url}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-gradient-to-r from-green-500 to-teal-500 px-4 py-2 rounded hover:from-green-600 hover:to-teal-600"
        >
          View Live
        </a>
      </div>
    </div>
  );
}