import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Switch from 'react-switch';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import WhatsAppButton from './components/WhatsAppButton';
import gsap from 'gsap';

function App() {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [theme, setTheme] = useState('dark');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    gsap.fromTo(
      ".nav-link",
      { opacity: 0, y: -20 },
      { opacity: 0.8, y: 0, stagger: 0.2, duration: 0.8, delay: 0.5 }
    );

    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div className={`${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'} min-h-screen flex flex-col`}>
        {/* Navbar */}
        <nav className={`sticky top-0 flex justify-between items-center p-4 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-200'} z-50 shadow-lg backdrop-blur-sm`}>
          <div className="flex items-center space-x-4">
            <div className="text-2xl font-bold">
              <img src="/logo.webp" alt="SAPCreation" className="h-8 inline-block" />
            </div>
            <Switch
              onChange={toggleTheme}
              checked={theme === 'dark'}
              offColor="#bbb"
              onColor="#4b5563"
              uncheckedIcon={<span style={{ display: 'block', textAlign: 'center', lineHeight: '26px' }}>☀️</span>}
              checkedIcon={<span style={{ display: 'block', textAlign: 'center', lineHeight: '26px' }}>🌙</span>}
              height={26}
              width={48}
              handleDiameter={24}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            />
          </div>
          {/* Burger Menu Icon (Visible on Mobile) */}
          <button className="md:hidden text-3xl focus:outline-none" onClick={toggleMenu}>
            {isMenuOpen ? '✖' : '☰'}
          </button>
          {/* Menu Links */}
          <div className={`${
            isMenuOpen ? 'flex' : 'hidden'
          } md:flex flex-col md:flex-row absolute md:static top-16 left-0 w-full md:w-auto ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-200'} md:bg-transparent z-40 md:flex items-center space-y-4 md:space-y-0 md:space-x-4 p-4 md:p-0`}>
            <Link to="/" className="nav-link hover:text-green-400" onClick={() => setIsMenuOpen(false)}>Home</Link>
            <Link to="/about" className="nav-link hover:text-green-400" onClick={() => setIsMenuOpen(false)}>About</Link>
            <Link to="/projects" className="nav-link hover:text-green-400" onClick={() => setIsMenuOpen(false)}>Projects</Link>
            <Link to="/contact" className="nav-link hover:text-green-400" onClick={() => setIsMenuOpen(false)}>Contact</Link>
            <Link to="/blog" className="nav-link hover:text-green-400" onClick={() => setIsMenuOpen(false)}>Blog</Link>
            <Link to="/contact" className="nav-link bg-gradient-to-r from-green-500 to-teal-500 px-4 py-2 rounded hover:from-green-600 hover:to-teal-600" onClick={() => setIsMenuOpen(false)}>Hire Me</Link>
          </div>
        </nav>

        {/* Routes */}
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home theme={theme} />} />
            <Route path="/about" element={<About theme={theme} />} />
            <Route path="/projects" element={<Projects theme={theme} />} />
            <Route path="/contact" element={<Contact theme={theme} />} />
            <Route path="/blog" element={<Blog theme={theme} />} />
          </Routes>
        </div>

        {/* WhatsApp Button */}
        <WhatsAppButton />

        {/* Back to Top Button */}
        {showBackToTop && (
          <button
            onClick={scrollToTop}
            className="back-to-top fixed bottom-8 right-8 bg-gradient-to-r from-green-500 to-teal-500 p-4 rounded-full shadow-lg hover:from-green-600 hover:to-teal-600 transition-all duration-300"
            style={{ zIndex: 100 }}
          >
            ↑
          </button>
        )}

        {/* Footer */}
        <footer className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-200'} p-4 text-center z-50 relative backdrop-blur-sm`}>
          <p>© 2025 SAPCreation. All rights reserved.</p>
          <div className="space-x-4 mt-2">
            <a href="https://www.facebook.com/arnold.handsomee" target="_blank" rel="noopener noreferrer" className="hover:text-green-400">Facebook</a>
            <a href="https://x.com/arnold87sm" target="_blank" rel="noopener noreferrer" className="hover:text-green-400">X</a>
            <a href="https://www.linkedin.com/in/sapcreation/" target="_blank" rel="noopener noreferrer" className="hover:text-green-400">Linkedin</a>
            <a href="https://github.com/Arnold87sm" target="_blank" rel="noopener noreferrer" className="hover:text-green-400">Github</a>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;