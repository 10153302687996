import { useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import Globe from '../components/Globe';
import ProjectModal from '../components/ProjectModal'; // Import the modal
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

export default function Projects({ theme }) {
  const [filter, setFilter] = useState('all');
  const [selectedProject, setSelectedProject] = useState(null); // State for modal

  const projects = [
    { id: 1, category: 'mobile', image: '/vip-taxi.jpg', title: 'VIP Taxi App', tech: 'Flutter, Dart, MySQL, Google Maps API', description: 'Built a taxi app with real-time routing and fare calculation.', theme, url: 'https://play.google.com/store/apps' }, // Placeholder; replace with actual URL
    { id: 2, category: 'web', image: '/autoparc.jpg', title: 'Autoparc RolCris', tech: 'HTML, CSS, JS, MySQL, Node.js', description: 'Custom site for a car dealership.', theme, url: 'https://www.autoparcrolcris.ro' },
    { id: 3, category: 'web', image: '/trasura.jpg', title: 'Trasura Cu Patru Cai', tech: 'WordPress', description: 'A site for horse-drawn carriage services.', theme, url: 'https://www.trasuracupatrucai.ro' },
    { id: 4, category: 'web', image: '/bettybeauty.jpg', title: 'Betty Beauty', tech: 'WordPress, WooCommerce', description: 'An e-commerce site for beauty products.', theme, url: 'https://www.bettybeauty.ro' },
    { id: 5, category: 'web', image: '/dezmembrari.jpg', title: 'Dezmembrari BMW', tech: 'WordPress', description: 'A site for BMW spare parts.', theme, url: 'https://www.dezmembraribmw-satumare.ro' },
    { id: 6, category: 'game', image: '/game1.jpg', title: 'Mobile Games', tech: 'Unity, Unreal Engine 5', description: '4 of my games published on Google Play Store.', theme, url: 'https://play.google.com/store/games' }, // Placeholder; replace with actual URL
  ];

  const filteredProjects = filter === 'all' ? projects : projects.filter(project => project.category === filter);

  useEffect(() => {
    gsap.fromTo(
      ".project-card",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, stagger: 0.3, duration: 1, scrollTrigger: { trigger: ".project-card" } }
    );
  }, [filter]);

  return (
    <div className="relative min-h-screen">
      {/* Globe Background */}
      <div className="absolute inset-0 z-5" style={{ zIndex: 5 }}>
        <Canvas>
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} />
          <Globe texture="/earth-texture.jpg" />
        </Canvas>
      </div>
      <div className="py-16 max-w-6xl mx-auto relative z-10">
        <h2 className="text-4xl font-bold text-center mb-12">My Projects</h2>
        {/* Filter Buttons */}
        <div className="flex justify-center space-x-4 mb-8">
          <button
            onClick={() => setFilter('all')}
            className={`px-4 py-2 rounded ${filter === 'all' ? 'bg-gradient-to-r from-green-500 to-teal-500' : theme === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} hover:bg-gradient-to-r hover:from-green-600 hover:to-teal-600`}
          >
            All
          </button>
          <button
            onClick={() => setFilter('web')}
            className={`px-4 py-2 rounded ${filter === 'web' ? 'bg-gradient-to-r from-green-500 to-teal-500' : theme === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} hover:bg-gradient-to-r hover:from-green-600 hover:to-teal-600`}
          >
            Web
          </button>
          <button
            onClick={() => setFilter('mobile')}
            className={`px-4 py-2 rounded ${filter === 'mobile' ? 'bg-gradient-to-r from-green-500 to-teal-500' : theme === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} hover:bg-gradient-to-r hover:from-green-600 hover:to-teal-600`}
          >
            Mobile
          </button>
          <button
            onClick={() => setFilter('game')}
            className={`px-4 py-2 rounded ${filter === 'game' ? 'bg-gradient-to-r from-green-500 to-teal-500' : theme === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} hover:bg-gradient-to-r hover:from-green-600 hover:to-teal-600`}
          >
            Games
          </button>
        </div>
        {/* Projects Grid */}
        <div key={filter} className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {filteredProjects.map(project => (
            <div key={project.id} className={`project-card ${theme === 'dark' ? 'bg-gradient-to-br from-gray-800 to-gray-700' : 'bg-gradient-to-br from-gray-200 to-gray-300'} p-6 rounded-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg`}>
              <img src={project.image} alt={project.title} className="w-full h-48 object-cover rounded mb-4 opacity-100" />
              <h3 className="text-xl font-semibold">{project.title}</h3>
              <p className={`${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>{project.tech}</p>
              <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>{project.description}</p>
              <button
                onClick={() => setSelectedProject(project)}
                className="mt-4 text-green-400 hover:underline"
              >
                View Details
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Project Modal */}
      {selectedProject && (
        <ProjectModal
          project={selectedProject}
          isOpen={!!selectedProject}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </div>
  );
}