import { useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Globe from '../components/Globe';
import gsap from 'gsap';

export default function About({ theme }) {
  useEffect(() => {
    gsap.fromTo(
      ".about-content",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, delay: 0.5 }
    );
    gsap.fromTo(
      ".skills-list li",
      { opacity: 0, x: -20 },
      { opacity: 1, x: 0, stagger: 0.2, duration: 0.8, delay: 1 }
    );
    gsap.fromTo(
      ".testimonial-card",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, stagger: 0.3, duration: 1, scrollTrigger: { trigger: ".testimonial-card" } }
    );
    gsap.fromTo(
      ".progress-bar",
      { width: 0 },
      {
        width: (index, element) => element.getAttribute('data-width') + '%',
        duration: 1.5,
        stagger: 0.2,
        scrollTrigger: { trigger: ".progress-bar" }
      }
    );
  }, []);

  return (
    <div className="relative">
      {/* Globe Background */}
      <div className="absolute inset-0 z-0">
        <Canvas>
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} />
          <Globe texture="/earth-night.jpg" />
        </Canvas>
      </div>
      {/* Content */}
      <div className="relative z-10 py-16 max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-12 about-content">About Me</h2>
        <p className={`text-lg ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'} about-content`}>
          I’m Arnold S. P., a passionate developer from Romania, with 5+ years of experience in web, mobile, and game development. I specialize in crafting modern, high-performance digital solutions tailored to each client’s needs. I’m fluent in English, Hungarian, and Romanian, and I’m excited to bring my skills to grow your business.
        </p>
        <div className="mt-8">
          <h3 className="text-2xl font-semibold mb-4 about-content">Skills</h3>
          <ul className={`skills-list space-y-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'} text-left`}>
            <li className="flex justify-between items-center">
              <span>HTML, CSS, JavaScript</span>
              <div className="w-3/4 flex items-center">
                <div className={`w-full ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} rounded-full h-2.5 mr-4`}>
                  <div className="progress-bar bg-green-500 h-2.5 rounded-full" data-width="95" style={{ width: '95%' }}></div>
                </div>
                <span className="text-sm font-semibold">95%</span>
              </div>
            </li>
            <li className="flex justify-between items-center">
              <span>React, Node.js</span>
              <div className="w-3/4 flex items-center">
                <div className={`w-full ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} rounded-full h-2.5 mr-4`}>
                  <div className="progress-bar bg-green-500 h-2.5 rounded-full" data-width="87" style={{ width: '87%' }}></div>
                </div>
                <span className="text-sm font-semibold">87%</span>
              </div>
            </li>
            <li className="flex justify-between items-center">
              <span>Flutter, Dart</span>
              <div className="w-3/4 flex items-center">
                <div className={`w-full ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} rounded-full h-2.5 mr-4`}>
                  <div className="progress-bar bg-green-500 h-2.5 rounded-full" data-width="85" style={{ width: '85%' }}></div>
                </div>
                <span className="text-sm font-semibold">85%</span>
              </div>
            </li>
            <li className="flex justify-between items-center">
              <span>Python, AI</span>
              <div className="w-3/4 flex items-center">
                <div className={`w-full ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} rounded-full h-2.5 mr-4`}>
                  <div className="progress-bar bg-green-500 h-2.5 rounded-full" data-width="70" style={{ width: '70%' }}></div>
                </div>
                <span className="text-sm font-semibold">70%</span>
              </div>
            </li>
            <li className="flex justify-between items-center">
              <span>Unity, Unreal Engine</span>
              <div className="w-3/4 flex items-center">
                <div className={`w-full ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} rounded-full h-2.5 mr-4`}>
                  <div className="progress-bar bg-green-500 h-2.5 rounded-full" data-width="82" style={{ width: '82%' }}></div>
                </div>
                <span className="text-sm font-semibold">82%</span>
              </div>
            </li>
          </ul>
        </div>

        {/* Testimonials Section */}
        <div className="mt-16">
          <h2 className="text-4xl font-bold mb-12">What Clients Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className={`testimonial-card ${theme === 'dark' ? 'bg-gradient-to-br from-gray-800 to-gray-700' : 'bg-gradient-to-br from-gray-200 to-gray-300'} p-6 rounded-lg`}>
              <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'} italic`}>"Arnold built an amazing website for my business—fast, responsive, and exactly what I needed!"</p>
              <p className="mt-4 font-semibold">Csaba V., Business Owner</p>
            </div>
            <div className={`testimonial-card ${theme === 'dark' ? 'bg-gradient-to-br from-gray-800 to-gray-700' : 'bg-gradient-to-br from-gray-200 to-gray-300'} p-6 rounded-lg`}>
              <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'} italic`}>"The mobile app Arnold developed for us exceeded our expectations. Highly recommend!"</p>
              <p className="mt-4 font-semibold">Ioan F., Business Owner</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}