import { useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Globe from '../components/Globe';
import gsap from 'gsap';

export default function Blog({ theme }) {
  useEffect(() => {
    gsap.fromTo(
      ".blog-post",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, stagger: 0.3, duration: 1, scrollTrigger: { trigger: ".blog-post" } }
    );
  }, []);

  return (
    <div className="relative min-h-screen">
      {/* Globe Background */}
      <div className="absolute inset-0 z-0">
        <Canvas>
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} />
          <Globe texture="/earth-night.jpg" />
        </Canvas>
      </div>
      {/* Content */}
      <div className="relative z-10 py-16 max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12">Blog coming soon..</h2>
        <div className="space-y-12">
          <div className={`blog-post ${theme === 'dark' ? 'bg-gradient-to-br from-gray-800 to-gray-700' : 'bg-gradient-to-br from-gray-200 to-gray-300'} p-6 rounded-lg`}>
            <h3 className="text-2xl font-semibold mb-4">How I Built the VIP Taxi App</h3>
            <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
              In this post, I’ll walk you through the process of building a taxi app using Flutter and the Google Maps API. From setting up real-time routing to calculating fares, I’ll share the challenges and solutions I encountered.
            </p>
            <a href="#" className="text-green-400 hover:underline mt-4 inline-block">Read More</a>
          </div>
          <div className={`blog-post ${theme === 'dark' ? 'bg-gradient-to-br from-gray-800 to-gray-700' : 'bg-gradient-to-br from-gray-200 to-gray-300'} p-6 rounded-lg`}>
            <h3 className="text-2xl font-semibold mb-4">Getting Started with Game Development in Unity</h3>
            <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
              Learn the basics of game development with Unity, including how to create your first game and publish it to the Google Play Store. I’ll share tips from my experience building mobile games.
            </p>
            <a href="#" className="text-green-400 hover:underline mt-4 inline-block">Read More</a>
          </div>
        </div>
      </div>
    </div>
  );
}