import { useLoader } from '@react-three/fiber';
import { OrbitControls, Sphere } from '@react-three/drei';
import { TextureLoader } from 'three';

export default function Globe({ texture }) {
  const globeTexture = useLoader(TextureLoader, texture);

  return (
    <>
      <Sphere args={[1, 100, 100]} scale={2}>
        <meshStandardMaterial map={globeTexture} />
      </Sphere>
      <OrbitControls enableZoom={false} autoRotate autoRotateSpeed={1} />
    </>
  );
}