import { useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Globe from '../components/Globe';
import Particles from 'react-tsparticles';
import { loadSlim } from 'tsparticles-slim';
import gsap from 'gsap';

export default function Home({ theme }) {
  const particlesInit = async (engine) => {
    await loadSlim(engine);
  };

  useEffect(() => {
    gsap.fromTo(
      ".hero-text",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, delay: 0.5 }
    );
    gsap.fromTo(
      ".hire-me-btn",
      { scale: 1 },
      { scale: 1.1, duration: 0.5, yoyo: true, repeat: -1, ease: "power1.inOut" }
    );
    gsap.fromTo(
      ".service-card",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, stagger: 0.3, duration: 1, scrollTrigger: { trigger: ".service-card" } }
    );
    console.log('Shooting stars rendered'); // Debug log
  }, []);

  return (
    <div className="relative">
        {/* Interactive Text */}
        <div className="absolute top-16 left-0 right-0 z-30 text-center">
        <p className={`interactive-text text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'} italic`}>
          Interactive globe and particles—feel free to play around!
        </p>
      </div>
      {/* Hero Section */}
      <div className="relative h-screen flex items-center justify-center">
        {/* Particles (z-0) */}
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            background: { color: { value: "transparent" } }, // Transparent background
            fpsLimit: 120,
            particles: {
              number: { value: 70, density: { enable: true, value_area: 800 } }, // Reduced number
              color: { value: theme === 'dark' ? "#ffffff" : "#000000" },
              shape: { type: "circle" },
              opacity: { value: 0.5, random: true },
              size: { value: 3, random: true },
              move: { enable: true, speed: 1, direction: "none", random: true, outModes: { default: "out" } },
            },
            interactivity: {
              events: { onHover: { enable: true, mode: "repulse" }, onClick: { enable: true, mode: "push" } },
              modes: { repulse: { distance: 100 }, push: { quantity: 4 } },
            },
            detectRetina: true,
          }}
          className="absolute inset-0 z-0"
          style={{ zIndex: 0 }}
        />
        {/* Shooting Stars (z-4) */}
        <div className="absolute inset-0 z-4" style={{ zIndex: 4 }}>
          <div className="shooting-star star-1"></div>
          <div className="shooting-star star-2"></div>
          <div className="shooting-star star-3"></div>
          <div className="shooting-star star-4"></div>
          <div className="shooting-star star-5"></div>
          <div className="shooting-star star-6"></div>
          <div className="shooting-star star-7"></div>
          <div className="shooting-star star-8"></div>
        </div>
        {/* Globe (z-5) */}
        <div className="absolute inset-0 z-5" style={{ zIndex: 5 }}>
          <Canvas>
            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 5]} />
            <Globe texture="/earth-texture.jpg" />
          </Canvas>
        </div>
        {/* Hero Text (z-20) */}
        <div className={`hero-text z-20 text-center ${theme === 'dark' ? 'bg-gray-900/50' : 'bg-gray-100/50'} p-6 rounded-lg`} style={{ zIndex: 20 }}>
          <h1 className="text-5xl font-bold mb-4">Hi, I’m Arnold P.</h1>
          <p className="text-2xl mb-6">Web, Mobile & Game Developer</p>
          <a href="/contact" className="hire-me-btn bg-gradient-to-r from-green-500 to-teal-500 px-6 py-3 rounded-lg hover:from-green-600 hover:to-teal-600 inline-block">Hire Me</a>
        </div>
      </div>
      {/* Wave Divider */}
      <div className="relative -mt-1">
        <svg className="w-full h-24" viewBox="0 0 1440 100" preserveAspectRatio="none">
          <path fill={theme === 'dark' ? "#111827" : "#e5e7eb"} d="M0,0 C280,100 720,0 1440,100 L1440,100 L0,100 Z" />
        </svg>
      </div>
      {/* Services Section */}
      <div className="py-16 max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12">My Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className={`service-card ${theme === 'dark' ? 'bg-gradient-to-br from-gray-800 to-gray-700' : 'bg-gradient-to-br from-gray-200 to-gray-300'} p-6 rounded-lg text-center`}>
            <h3 className="text-xl font-semibold mb-4">Web Development</h3>
            <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>Crafting modern, responsive websites using HTML, CSS, JavaScript, React, and more.</p>
          </div>
          <div className={`service-card ${theme === 'dark' ? 'bg-gradient-to-br from-gray-800 to-gray-700' : 'bg-gradient-to-br from-gray-200 to-gray-300'} p-6 rounded-lg text-center`}>
            <h3 className="text-xl font-semibold mb-4">Mobile Apps</h3>
            <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>Building cross-platform mobile apps with Flutter and Dart for iOS and Android.</p>
          </div>
          <div className={`service-card ${theme === 'dark' ? 'bg-gradient-to-br from-gray-800 to-gray-700' : 'bg-gradient-to-br from-gray-200 to-gray-300'} p-6 rounded-lg text-center`}>
            <h3 className="text-xl font-semibold mb-4">Game Development</h3>
            <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>Creating engaging games with Unity and Unreal Engine, published on Google Play Store.</p>
          </div>
        </div>
      </div>
    </div>
  );
}